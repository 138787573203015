.app {
  width: 100vw;
  margin: 0;
  /*background-color: black;
  background-image: linear-gradient(to right, rgb(36, 41, 41), rgb(31, 38, 41));*/
}

body {
  background-image: linear-gradient(
    to right,
    rgb(190, 197, 199),
    rgb(221, 232, 236)
  );
  background-size: 100%;
}

.router {
  color: inherit;
}
.appscr {
  text-align: center;
  height: 5000px;
}
.scrollTop {
  position: fixed;
  width: 100%;
  bottom: 20px;
  align-items: center;
  height: 20px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
}

.scrollTop:hover {
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
