.nav {
  display: flex;
  color: skyblue;
  padding: 10px;
  padding-left: 35px;
}

/* Remove Scroll bar for chrom, safari and opera*/
.header::-webkit-scrollbar {
  display: none;
}

/* Remove Scroll bar for IE, Edge and Firefox*/
.header {
  -moz-overflow-style: none;
  scrollbar-width: none;
}

.nav > h2 {
  cursor: pointer;
  margin-left: 30px;
  margin-right: 30px;
  font-weight: 100;
  opacity: 0.7;
  transition: transform 50ms ease-out;
}

.nav > h2:hover {
  opacity: 1;
  transform: scale(1.2);
}
