.header {
  display: flex;
  align-items: center;
  overflow-x: scroll;
}

/* Remove Scroll bar for chrom, safari and opera*/
.header::-webkit-scrollbar {
  display: none;
}

/* Remove Scroll bar for IE, Edge and Firefox*/
.header {
  -moz-overflow-style: none;
  scrollbar-width: none;
}

.header_icon > .MuiSvgIcon-root {
  font-size: 30px;
  margin-left: auto;
  margin-right: auto;
}

.header_icon-active > p {
  display: inline-flex !important;
}

.header_icon:hover > p {
  display: inline-flex;
}

.header_icons {
  display: flex;
  align-items: center;
  text-align: center;
}

.header_icon > p {
  display: none;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-left: auto;
  margin-right: auto;
  font-size: small;
}

.header_icon {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

img {
  object-fit: contain;
  height: 60px;
}

.headertext {
  font-size: 16px !important;
}
