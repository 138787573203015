.videocard {
  border-block-color: black;
  width: 300px;
  height: auto;
  padding: 10px;
  transition: transform 100ms;
}

.videocard:hover {
  transform: scale(1.09);
}

.videocardimg {
  height: 130px;
  object-fit: contain;
  width: 100%;
  filter: drop-shadow(5px 5px 5px #222);
}

.detailicon {
  cursor: pointer;
}

.MuiPaper-root {
  background-image: linear-gradient(
    to right,
    rgb(245, 249, 250),
    rgb(221, 232, 236)
  );
  font-size: 5px !important;
}
