App {
  text-align: center;
  height: 5000px;
}

.scrollTop {
  position: fixed;
  right: 1%;
  width: 100%;
  bottom: 20px;
  align-items: center;
  height: 20px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
  color: skyblue;
  transform: "rotate(90deg)";
  background: black;
  border-radius: 100%;
}

.scrollTop:hover {
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

.dot {
  height: 30px;
  width: 30px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
